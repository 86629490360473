/*import Packery from 'packery';
import imagesloaded from 'imagesloaded';*/

/*$(window).on('load', function () {
    $("body #overlayLoading").fadeOut("slow", function () {
        // Animation complete.
        $('body').removeClass('loading');
        $("#overlayLoading").remove();
    });
});*/
window.onload = function () {
    const body = document.querySelector("body");
    const overlayLoading = document.querySelector("#overlayLoading");
    overlayLoading.addEventListener('animationend', function () {
        body.classList.remove('loading');
        overlayLoading.remove();
    });
    overlayLoading.classList.add("fadeout");


    /*var elemts = document.querySelectorAll('.grid');
    for (var i = 0, len = elemts.length; i < len; i++) {        
        var pckry = new Packery(elemts[i], {
            columnWidth: '.grid-sizer',
            itemSelector: '.grid-item',
            percentPosition: true
        });
        imagesloaded(pckry).on('progress', function () {
            // layout Packery after each image loads
            pckry.packery();
        });
    }*/
};

function updateRangeText(input) {
    let texto = input.parentElement.querySelector('.form-text')
    let max = input.max == "" ? 100 : input.max;
    if (texto != null) {
        texto.innerHTML = `${input.value} sobre ${max}`;
    } else {
        console.warn(`Este input de tipo range no tiene un valor visible.Comprueba si deberias de añadir uno.`, input);
    }
}
const inputsRange = document.querySelectorAll("input[type=\"range\"]");
inputsRange.forEach(input => {
    updateRangeText(input);
    input.addEventListener('input', function () {
        updateRangeText(input);
    });
});

function socialWindow(url) {
    var tamano = 700;
    var left = (screen.width - tamano) / 2;
    var top = (screen.height - tamano) / 2;
    var params = "menubar=no,toolbar=no,status=no,width=" + tamano + ",height=" + tamano + ",top=" + top + ",left=" + left;
    window.open(url, "NewWindow", params);
}

function setShareLinks() {
    var tweet = "";
    var ogDesc = document.querySelectorAll("meta[property='og:description']")
    ogDesc.forEach(function (item) {
        tweet = typeof item.attributes.content == "undefined" ? "" : item.attributes.content.textContent;
    });
    var pageUrl = encodeURIComponent(document.URL);
    tweet = encodeURIComponent(tweet);
    //tweet = (tweet != '') ? '' : tweet;

    var fb = document.querySelectorAll(".socialShare .facebook")
    fb.forEach(function (item) {
        item.addEventListener("click", function () {
            url = "https://www.facebook.com/sharer.php?u=" + pageUrl;
            socialWindow(url);
        });
    });

    var tw = document.querySelectorAll(".socialShare .twitter")
    tw.forEach(function (item) {
        item.addEventListener("click", function () {
            url = "https://twitter.com/intent/tweet?url=" + pageUrl + "&text=" + tweet + "&via=" + item.dataset.via;
            socialWindow(url);
        });
    });

    var lin = document.querySelectorAll(".socialShare .linkedin")
    lin.forEach(function (item) {
        item.addEventListener("click", function () {
            url = "https://www.linkedin.com/shareArticle?mini=true&url=" + pageUrl;
            socialWindow(url);
        })
    });

    var wa = document.querySelectorAll(".socialShare .whatsapp")
    wa.forEach(function (item) {
        item.addEventListener("click", function () {
            url = "https://wa.me/?text=" + pageUrl;
            socialWindow(url);
        })
    });

    var tm = document.querySelectorAll(".socialShare .tumblr")
    tm.forEach(function (item) {
        item.addEventListener("click", function () {
            url = "http://www.tumblr.com/share/link?url=" + pageUrl;
            socialWindow(url);
        })
    });

    var pr = document.querySelectorAll(".socialShare .print")
    pr.forEach(function (item) {
        item.addEventListener("click", function () {
            window.print()
        })
    });

    var ml = document.querySelectorAll(".socialShare .mail")
    ml.forEach(function (item) {
        item.addEventListener("click", function () {
            url = "mailto:?body=" + pageUrl;
            socialWindow(url);
        })
    });
}
setShareLinks();